<template>
    <div class="teacher-capacity-table">
        <w-data-table
            :loading="isDataLoading"
            :title="$trans('Capacity')"
            icon="CAPACITY"
            :headers="capacitiesHeaders"
            :items="computedItems"
            :items-per-page="-1"
            hide-default-footer
        >
            <template #filters>
                <v-select
                    v-model="filterByStudyPeriod"
                    :items="studyPeriodsFilterItems"
                    :placeholder="$trans('Study period')"
                    outlined
                    dense
                    hide-details
                    clearable
                >
                    <template #prepend-inner>
                        <w-icon dense value="PERIOD" class="prepend-inner-icon"/>
                    </template>
                </v-select>
                <v-select
                    v-model="filterByDepartment"
                    :items="departments"
                    :placeholder="$trans('Department')"
                    item-text="title"
                    item-value="uuid"
                    outlined
                    dense
                    hide-details
                    clearable
                >
                    <template #prepend-inner>
                        <w-icon dense value="DEPARTMENT" class="prepend-inner-icon"/>
                    </template>
                </v-select>
                <v-select
                    v-model="filterByLessonType"
                    :placeholder="$trans('Lesson type')"
                    :items="lessonTypes"
                    item-text="title"
                    item-value="uuid"
                    outlined
                    dense
                    hide-details
                    clearable
                >
                    <template #prepend-inner>
                        <w-icon dense value="LESSON" class="prepend-inner-icon"/>
                    </template>
                </v-select>
                <v-select
                    v-model="filterByGroup"
                    :placeholder="$trans('Group')"
                    :items="filterByGroupItems"
                    item-text="title"
                    item-value="uuid"
                    outlined
                    dense
                    hide-details
                    clearable
                >
                    <template #prepend-inner>
                        <w-icon dense value="GROUP" class="prepend-inner-icon"/>
                    </template>
                </v-select>
            </template>

            <template v-if="!readOnly" #actions>
                <v-btn
                    icon
                    color="primary"
                    @click="onShowForm(null)"
                >
                    <w-icon value="ADD"/>
                </v-btn>
            </template>

            <template #item.title="{ item }">
                <div class="font-weight-bold">{{ item.course.title }}</div>
                <small class="caption">
                    {{ departmentLabel(item.department_uuid) }}
                </small>
            </template>

            <template #item.lesson_type_uuid="{ item }">
                <div>{{ lessonTypeLabel(item.lesson_type_uuid) }}</div>
                <small class="caption d-none d-md-block">
                    {{ studyPeriodLabel(item.study_period_uuid) }}
                </small>
            </template>

            <template #item.groups="{ item }">
                <div class="py-1 text-truncate">
                    <div
                        v-for="group in item.groups"
                        :key="group.uuid"
                    >
                        {{ group.title }}
                    </div>
                </div>
            </template>
            <template #item.actions="{ item }">
                <div class="d-flex align-center justify-end text-truncate">
                    <v-btn
                        :disabled="!authCanChangeCapacity(item)"
                        color="success"
                        icon
                        @click="onShowForm(item)"
                    >
                        <w-icon value="EDIT"/>
                    </v-btn>
                    <v-btn
                        :disabled="!authCanChangeCapacity(item)"
                        color="error"
                        icon
                        @click="onDetachCapacity(item)"
                    >
                        <w-icon value="CLOSE"/>
                    </v-btn>
                </div>
            </template>

            <template v-if="!readOnly" #footer>
                <v-btn
                    text
                    block
                    tile
                    color="primary"
                    class="py-6"
                    @click="onShowForm(null)"
                >
                    <w-icon left value="ADD"/>
                    {{ addCapacityTitle }}
                </v-btn>
            </template>
        </w-data-table>
        <w-dialog-drawer
            :key="componentKey"
            v-model="isDialog"
            :loading="departmentsLoading"
            :title="formTitle"
            :disabled="isDialogDisabled"
            width="500"
        >
            <div v-if="isFormItem" class="px-4 pt-3">
                <v-list dense>
                    <v-card outlined>
                        <v-list-item>
                            <v-list-item-icon>
                                <w-icon value="COURSE"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ formItem.course.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                        departmentLabel(formItem.department_uuid)
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                    <v-card outlined class="mt-4">
                        <v-list-item>
                            <v-list-item-icon>
                                <w-icon value="LESSON"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ lessonTypeLabel(formItem.lesson_type_uuid) }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                        studyPeriodLabel(formItem.study_period_uuid)
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-list>
            </div>
            <div class="pa-4">
                <w-simple-form
                    :fields="formFields"
                    :item="formItem"
                    :errors="responseErrors"
                    @input:department_uuid="onSelectedDepartment"
                    @input:lesson_type_uuid="onSelectedLessonType"
                    @save="onAttachCapacity"
                    @update="onUpdateCapacity"
                >
                    <template #field.course_uuid="{ data, errors, isEditing }">
                        <v-autocomplete
                            v-model="data.course_uuid"
                            :disabled="!data.department_uuid"
                            :readonly="isEditing"
                            :label="$trans('Course')"
                            :items="foundCourses"
                            :loading="isSearchingCourse"
                            :search-input.sync="searchCourse"
                            :error-messages="errors"
                            dense
                            outlined
                            hide-no-data
                            hide-selected
                            :clearable="!isEditing"
                            item-text="title"
                            item-value="uuid"
                            :placeholder="$trans('Start typing to Search')"
                            :rules="[ rules.required ]"
                            @click:clear="onClearFoundCourses"
                            @change="onSelectCourse"
                        >
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item dense v-bind="attrs" v-on="on" style="height: 2.5rem">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        <v-list-item-subtitle class="caption">{{
                                                item.groups | courseGroupsFilter
                                            }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </template>
                    <template #field.groups="{ data, errors }">
                        <v-select
                            v-model="data.groups"
                            :disabled="!data.course_uuid || !data.lesson_type_uuid"
                            :label="$trans('Groups')"
                            :items="selectedCourseGroups"
                            itemText="title"
                            itemValue="uuid"
                            :multiple="isSelectGroupsMultiple"
                            :error-messages="errors"
                            outlined
                            dense
                            :clearable="!isFormItem"
                            :rules="[ rules.required ]"
                        />
                    </template>
                </w-simple-form>
            </div>
        </w-dialog-drawer>
    </div>
</template>

<script>
import { CAPACITY } from '@/widgets/components/WIcon/icons'
import hasStudyPeriodsPropertyMixin from '@apps/school/mixins/hasStudyPeriodsPropertyMixin'
import { getCoursesCollection } from '@apps/school/api/courses'
import { required } from '@/utils/validation'
import { deleteCapacity, getCapacity, storeCapacity, updateCapacity } from '@apps/school/api/capacity'
import { getDepartmentsCollection } from '@apps/school/api/departments'
import ConfirmationSets from '@/utils/ConfirmationSets'
import EventBus from '@/utils/EventBus'
import Debugger from '@/utils/Debugger'

export default {
    name: 'TeacherCapacity',
    mixins: [
        hasStudyPeriodsPropertyMixin
    ],
    props: {
        uuid: {
            type: String,
            default: undefined
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    filters: {
        courseGroupsFilter(groups) {
            const items = groups.map(o => o.title)
            return items.join(', ')
        }
    },
    data() {
        return {
            componentKey: 0,
            loading: false,
            departmentsLoading: false,
            items: [],
            departments: [],
            lessonTypes: [],
            isLoading: false,
            isDialog: false,
            isDialogDisabled: false,
            isSearchingCourse: false,
            filterByStudyPeriod: null,
            filterByDepartment: null,
            filterByGroup: null,
            filterByLessonType: null,
            selectedDepartment: null,
            selectedCourse: null,
            selectedLessonType: null,
            searchCourse: null,
            foundCourses: [],
            departmentIcon: CAPACITY,
            formItem: {},
            rules: {
                required
            },
            responseErrors: {}
        }
    },
    computed: {
        isDataLoading() {
            return this.loading || this.isLoading
        },
        isFormItem() {
            return Object.keys(this.formItem).length > 0
        },
        studyPeriodsFilterItems() {
            const items = []

            this.studyPeriods.forEach(o => {
                items.push({
                    text: o.values.label,
                    value: o.uuid
                })
            })

            return items
        },
        addCapacityTitle() {
            return `${this.$trans('Add capacity')}`
        },
        formTitle() {
            return this.isFormItem
                ? this.$trans('Update capacity')
                : this.$trans('Add capacity')
        },
        capacitiesHeaders() {
            const headers = [
                {
                    text: this.$trans('Course'),
                    value: 'title',
                    sortable: false
                },
                {
                    text: this.$trans('Lesson type'),
                    value: 'lesson_type_uuid',
                    sortable: false
                },
                {
                    text: this.$trans('Groups'),
                    value: 'groups',
                    sortable: false
                },
                {
                    text: this.$trans('Capacity'),
                    align: 'center',
                    value: 'value',
                    sortable: false
                }
            ]

            if(!this.readOnly) {
                headers.push({
                    value: 'actions',
                    align: 'right',
                    sortable: false
                })
            }

            return headers
        },
        computedItems() {
            let items = [].concat(this.items)

            if (this.filterByStudyPeriod) {
                items = items.filter(o => o.study_period_uuid === this.filterByStudyPeriod)
            }

            if (this.filterByDepartment) {
                items = items.filter(o => o.department_uuid === this.filterByDepartment)
            }

            if (this.filterByLessonType) {
                items = items.filter(o => o.lesson_type_uuid === this.filterByLessonType)
            }

            if (this.filterByGroup) {
                items = items.filter(o => o.groups.find(g => g.uuid === this.filterByGroup))
            }

            return items
        },
        filterByGroupItems() {
            const groups = []

            this.computedItems.forEach(capacity => {
                capacity.groups.forEach(group => {
                    if (!groups.find(o => o.uuid === group.uuid)) {
                        groups.push({
                            uuid: group.uuid,
                            title: group.title
                        })
                    }
                })
            })

            return groups
        },
        selectedCourseGroups() {
            if (!this.selectedCourse) {
                return []
            }

            const course = this.foundCourses.find(o => o.uuid === this.selectedCourse)
            return course ? course.groups : []
        },
        isSelectGroupsMultiple() {
            const selected = this.lessonTypes.find(o => o.uuid === this.selectedLessonType)

            if (!selected) {
                return false
            }

            return selected.group === 'common'
        },
        formFields() {
            const fields = []

            if (!this.isFormItem) {
                fields.push({
                    name: 'department_uuid',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Department'),
                        items: this.departments,
                        itemValue: 'uuid',
                        itemText: 'title',
                        dense: true,
                        outlined: true,
                        rules: [required]
                    }
                })

                fields.push({
                    name: 'course_uuid'
                })

                fields.push({
                    name: 'lesson_type_uuid',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Lesson type'),
                        items: this.lessonTypes,
                        itemValue: 'uuid',
                        itemText: 'title',
                        dense: true,
                        outlined: true,
                        rules: [required]
                    }
                })

                fields.push({
                    name: 'study_period_uuid',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Study period'),
                        items: this.studyPeriods,
                        itemValue: 'uuid',
                        itemText: 'values.label',
                        dense: true,
                        outlined: true,
                        rules: [required]
                    }
                })
            }

            fields.push({
                name: 'groups'
            })

            fields.push({
                name: 'value',
                type: 'v-text-field',
                props: {
                    label: this.$trans('Capacity'),
                    persistentHint: true,
                    type: 'number',
                    dense: true,
                    outlined: true,
                    rules: [required]
                }
            })

            return fields
        }
    },
    watch: {
        isDialog(value) {
            if (!value) {
                this.componentKey++
                this.resetFormFlags()
            }
        },
        searchCourse(search) {
            const department = this.selectedDepartment

            if (!search) {
                this.isSearchingCourse = false
                return
            }

            // Items have already been loaded
            if (this.foundCourses.length > 0) return

            // Items have already been requested
            if (this.isSearchingCourse) return

            this.isSearchingCourse = true

            // Lazily load input items
            getCoursesCollection({
                search,
                department,
                with: 'groups'
            })
                .then(response => {
                    this.foundCourses = response.data.data
                })
                .catch(err => {
                    Debugger.log(err)
                })
                .finally(() => (this.isSearchingCourse = false))
        }
    },
    async mounted() {
        await this.fetchDepartments()
        await this.fetchCapacity()
    },
    created() {
        EventBus.$on('teacher-departments-synced', this.fetchDepartments)
    },
    methods: {
        async fetchDepartments() {
            if (!this.uuid) {
                return
            }

            this.departmentsLoading = true

            try {
                const response = await getDepartmentsCollection({
                    teacher: this.uuid
                })

                this.departments = response.data.data
                this.departmentsLoading = false
            } catch (e) {
                this.departmentsLoading = false
            }
        },
        async fetchCapacity() {
            if (!this.uuid) {
                return
            }

            try {
                this.loading = true

                const response = await getCapacity({
                    teacher: this.uuid,
                    period: 'all',
                    with: 'course:title,uuid,description;capacityAmount'
                })

                this.items = response.data.data
                this.lessonTypes = response.data.lesson_types.filter(o => o.group !== 'independent')

                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        lessonTypeLabel(uuid) {
            const lessonType = this.lessonTypes.find(o => o.uuid === uuid)
            return lessonType ? lessonType.description || lessonType.title : ''
        },
        studyPeriodLabel(uuid) {
            const period = this.studyPeriods.find(o => o.uuid === uuid)
            return period ? period.values.label : ''
        },
        departmentLabel(uuid) {
            const department = this.departments.find(o => o.uuid === uuid)
            return department ? department.title : ''
        },
        authCanChangeCapacity(item) {
            if(!this.$auth.isAssistant()) return true

            return this.$auth.hasPermission('department', item.department_uuid)
        },
        resetFormFlags() {
            this.formItem = {}
            this.foundCourses = []
            this.isSearchingCourse = false
            this.searchCourse = null
            this.selectedCourse = null
            this.selectedDepartment = null
            this.selectedLessonType = null
        },
        async onShowForm(item) {
            if (item !== null) {
                this.resetFormFlags(item)

                this.searchCourse = item.course.title
                this.selectedCourse = item.course_uuid
                this.selectedDepartment = item.department_uuid
                this.selectedLessonType = item.lesson_type_uuid

                this.formItem = Object.assign({}, item)
                this.formItem.groups = this.formItem.groups.map(o => o.uuid)
                this.formItem.groups = this.isSelectGroupsMultiple
                    ? this.formItem.groups
                    : this.formItem.groups.shift()
            } else {
                this.resetFormFlags()
            }

            this.isDialog = true
        },
        onSelectedDepartment(uuid) {
            this.selectedDepartment = uuid
        },
        onSelectedLessonType(uuid, formData) {
            this.selectedLessonType = uuid
            formData.groups = null
        },
        onClearFoundCourses() {
            this.foundCourses = []
            this.selectedCourse = null
        },
        onSelectCourse(uuid) {
            this.selectedCourse = uuid
        },
        onAttachCapacity(data) {
            data.teacher_uuid = this.uuid
            this.isDialogDisabled = true

            storeCapacity(data)
                .then(() => {
                    this.isDialog = false
                    this.isDialogDisabled = false

                    this.fetchCapacity()

                    this.$emit('synced')
                })
                .catch(error => {
                    this.isDialogDisabled = false

                    if (error.response.data.errors) {
                        this.responseErrors = error.response.data.errors
                    }
                })
        },
        onUpdateCapacity(data) {
            this.isDialogDisabled = true

            updateCapacity(this.formItem.uuid, data)
                .then(() => {
                    this.isDialog = false
                    this.isDialogDisabled = false

                    this.fetchCapacity()

                    this.$emit('synced')
                })
                .catch(error => {
                    this.isDialogDisabled = false

                    if (error.response.data.errors) {
                        this.responseErrors = error.response.data.errors
                    }
                })
        },
        async onDetachCapacity(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteSet({
                title: `${this.$trans('Delete')} ${item.course.title}`
            }))

            if (!isConfirm) {
                return
            }

            this.isLoading = true

            deleteCapacity(item.uuid)
                .then(() => {
                    this.isLoading = false

                    this.fetchCapacity()

                    this.$emit('synced')
                })
                .catch(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<style lang=scss>
</style>
